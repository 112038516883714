import React, { useState } from 'react';
import { Box, Typography, Container, Button, Card, Grid, IconButton } from '@mui/material';
import { styled } from '@mui/system';
import { Link } from 'react-router-dom';
import { ExpandMore, ExpandLess } from '@mui/icons-material';
import image from '../images/lthcHQ.jpeg';

const CenteredContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  minHeight: 'calc(100vh - 64px)',
  width: '100%',
  padding: '0 16px',
  gap: '24px',
});

const HomePage = () => {
  const [expandedCards, setExpandedCards] = useState({});

  const handleExpandClick = (cardName) => {
    setExpandedCards((prev) => ({
      ...prev,
      [cardName]: !prev[cardName],
    }));
  };

  return (
    <CenteredContainer maxWidth="lg">
      <Box textAlign="center" width="100%">
        <img src={image} alt="LTHC HQ" style={{ width: '100%', height: 'auto', marginBottom: '24px' }} />
        <Typography fontWeight="bold" variant="h3" gutterBottom>
          Welcome!
        </Typography>
        <Button
          component={Link}
          to='/resourcestab/resources'
          sx={{
            backgroundColor: '#ecb81c',
            color: 'black',
            borderRadius: '20px',
            padding: '20px 30px',
            marginBottom: '75px', 
            underline: 'black',
            fontSize: '22px',
            fontWeight: 'bold',
            textTransform: "none"
          }}
        >
          Discover Resources!
        </Button>
        <Typography fontWeight="bold" variant="h4" gutterBottom>
          Services
        </Typography>
      </Box>
      <Grid container spacing={3} width="100%">
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Housing
              <IconButton onClick={() => handleExpandClick('housing')}>
                {expandedCards['housing'] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>

            {expandedCards['housing'] && (
              <Typography>
                Providing immediate shelter as well as long-term stay to assist working or finding a permanent home.
              </Typography>
            )}   

          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Food
              <IconButton onClick={() => handleExpandClick('food')}>
                {expandedCards['food'] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>

            {expandedCards['food'] && (
              <Typography>
                Options include meal assistance such as food pantries or soup kitchens for everyday essentials. Culinary programs are also available to learn simple cooking skills.
              </Typography>
            )}

          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Financial
              <IconButton onClick={() => handleExpandClick('financial')}>
                {expandedCards['financial'] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>

            {expandedCards['financial'] && (
              <Typography>
                Assistance with your financial goals such as setting up a bank account or understanding any aspect of lending.
              </Typography>
            )}

          </Card>
        </Grid>
        <Grid item xs={12} md={6} lg={3}>
          <Card sx={{ backgroundColor: '#D6F1FF', padding: '16px', borderRadius: '20px', height: "100%" }}>
            <Typography variant="h5" gutterBottom fontWeight="bold" textAlign="center">
              Medical
              <IconButton onClick={() => handleExpandClick('medical')}>
                {expandedCards['medical'] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Typography>

            {expandedCards['medical'] && (
              <Typography>
                For connecting you with medical care including for immediate illness or regular checkups. There are also people you can talk to if you’re struggling with addiction or mental health.
              </Typography>
            )}

          </Card>
        </Grid>
      </Grid>
    </CenteredContainer>
  );
};

export default HomePage;
